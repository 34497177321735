import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "osdm-gateway-not-in-production"
    }}>{`OSDM-Gateway (not in production)`}</h1>
    <p>{`OSDM-Gateway translates between Entur-models and the OSDM-specification.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      